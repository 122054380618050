import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import shape1 from '../../assets/images/our-mission/our-mission-shape1.png'
import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'
import mission1 from '../../assets/images/our-mission/our-mission1.png'

const OurMission = () => {
    return (
        <section className="our-mission-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> ERP SERVICES
                                </span>
                                <h2>ERP Implementation & Data Migration</h2>
                                <p>Decades of experience in Implementing, upgrading & migrating ERP solutions both from a functional & technical perspectives.</p>
                                <ul className="our-mission-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Pay to Cash
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Lead to Cash Order
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Data Migration
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Financial Accounting
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Materials Management
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Plant Management
                                        <img src={shape2} alt="banner" />
                                    </li>
                                </ul>
                                <p>
                                    Our success is driven by years of techno-funtional experience & an array of accelerators & consulting assets that expedite & standardize your deployments ensuring 100% satisfied customers!.</p>

                                <Link to="/servicess" className="default-btn">
                                    <i className="flaticon-right"></i>
                                    Discover our ERP Consulting Services
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={mission1} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurMission