import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import icon1 from '../../assets/images/services/service-icon1.png'
import icon2 from '../../assets/images/services/service-icon2.png'
import icon3 from '../../assets/images/services/service-icon3.png'
import icon4 from '../../assets/images/services/service-icon4.png'
import icon5 from '../../assets/images/services/service-icon5.png'
import icon6 from '../../assets/images/services/service-icon6.png'
import shape1 from '../../assets/images/services/service-shape1.png'
import shape2 from '../../assets/images/services/service-shape2.png'

const OurServices = () => {
    return (
        <section className="services-area ptb-100 bg-e3fbff">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="image" />
                        Our Services
                    </span>
                    <h2>We Offer Professional Solutions For Business</h2>
                    <p>We strongly believe that Growth & Change is driven by Data. Thus, all our solutions & services are an embodiment of that belief & an undying will to deliver high-quality solutions & services to our clients.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon1} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Big Data
                                </Link>
                            </h3>
                            <p>Use our advanced analytics techniques to identify key patterns & insights in very large diverse data sets to drive business benefits.</p>

                            <Link to="/service-detailsbd" className="default-btn">
                                <i className="flaticon-right"></i>
                                Read More <span></span>
                            </Link>

                            <div className="shape1">
                                <img src={shape1} alt="image" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon2} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Data Visualization
                                </Link>
                            </h3>
                            <p>Highlight key business information in an easy to consume story telling visual dashboards using today's market leading data visualization tools.</p>

                            <Link to="/service-detailsdv" className="default-btn">
                                <i className="flaticon-right"></i>
                                Read More <span></span>
                            </Link>

                            <div className="shape1">
                                <img src={shape1} alt="image" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon3} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Master Data Management
                                </Link>
                            </h3>
                            <p>Author, Deploy & safeguard master data management policies, principles, guidelines using our MDM framework & decades of experience.</p>

                            <Link to="/service-detailsmdm" className="default-btn">
                                <i className="flaticon-right"></i>
                                Read More <span></span>
                            </Link>

                            <div className="shape1">
                                <img src={shape1} alt="image" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon4} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Project Management
                                </Link>
                            </h3>
                            <p>Implement SAP PS module & improve your Project Management process such as cost and budget management, scheduling, requisitioning of materials and services.</p>

                            <Link to="/service-detailsg" className="default-btn">
                                <i className="flaticon-right"></i>
                                Read More <span></span>
                            </Link>

                            <div className="shape1">
                                <img src={shape1} alt="image" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon5} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Material Management
                                </Link>
                            </h3>
                            <p>Helping you implement SAP MM module and improve your materials, inventory and warehouse management solution to overcome the challenge of proper materials.</p>

                            <Link to="/service-detailse" className="default-btn">
                                <i className="flaticon-right"></i>
                                Read More <span></span>
                            </Link>

                            <div className="shape1">
                                <img src={shape1} alt="image" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={icon6} alt="image" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Financial Accounting
                                </Link>
                            </h3>
                            <p>We will help you implement SAP Financial accounting solutions and optimize your process to deliver reliable internal & external reporting requirements.</p>

                            <Link to="/service-detailsc" className="default-btn">
                                <i className="flaticon-right"></i>
                                Read More <span></span>
                            </Link>

                            <div className="shape1">
                                <img src={shape1} alt="image" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="image" />
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </section>
    )
}

export default OurServices