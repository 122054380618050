import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import blog1 from '../../assets/images/blog/blog-img1.jpg'
import blog2 from '../../assets/images/blog/blog-img2.jpg'
import blog6 from '../../assets/images/blog/blog-img6.jpg'

import shape1 from '../../assets/images/shape/vector-shape1.png'
import shape2 from '../../assets/images/shape/vector-shape2.png'

const OurBlog = () => {
    return (
        <section className="blog-area pt-100 pb-70 bg-fafafa">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" />
                        Our Blog
                    </span>
                    <h2>Latest Valuable Insights</h2>
                    <p>Coming Soon</p>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <Link to="/blog">
                                    <img src={blog1} alt="blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                
                                <h3>
                                    <Link to="/blog">
                                        Coming Soon!
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <Link to="/blog">
                                    <img src={blog2} alt="blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                
                                <h3>
                                    <Link to="/blog">
                                        Coming Soon!
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <Link to="/blog">
                                    <img src={blog6} alt="blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                
                                <h3>
                                    <Link to="/blog">
                                        Coming Soon!
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape1">
                <img src={shape1} alt="shape" />
            </div>
            <div className="vector-shape2">
                <img src={shape2} alt="shape" />
            </div>
        </section>
    )
}

export default OurBlog