import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurFeatures from "../components/Index/OurFeatures"
import AboutUs from "../components/Index/AboutUs"
import OurMission from "../components/Index/OurMission"
import FunFacts from "../components/Index/Funfacts"
import OurServices from "../components/Index/OurServices"
import HowItWork from "../components/Index/HowItWork"


import ProjectStartArea from "../components/Index/ProjectStartArea"
import OurBlog from "../components/Index/OurBlog"





const Home = () => {
  return (
    <Layout>
      <Navbar />
          <Banner />
          <OurFeatures />
          <AboutUs />
          <OurMission />
          <FunFacts />
          <OurServices />
          <HowItWork />
          
          <ProjectStartArea />
          <OurBlog />
          
          
          <Footer />
       </Layout>
      
      
      
      
   
  )
}

export default Home